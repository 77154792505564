import React from 'react'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { ParagraphLarge, ParagraphMedium } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { ourColors } from './Colors'
import constants from '../constants'

interface LogoFooterProps {
  marginTop: string
}
export const LogoFooter = (props: LogoFooterProps) => {
  const [css] = useStyletron()

  const contactLink = (
    <a
      href={'mailto:' + constants.supportEmail}
      className={css({
        color: ourColors.white
      })}
    >
      contact us
    </a>
  )

  return (
    <div className={css({ marginTop: props.marginTop, backgroundColor: ourColors.webBlack })}>
      <Grid>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <ParagraphLarge marginTop={'28px'} color={ourColors.white}>
            Powered by
          </ParagraphLarge>
        </Cell>
        <Cell span={2}>
          <img
            src="/logos/vsg_logo.png"
            className={css({ marginRight: 0, marginBottom: '39px' })}
          />
        </Cell>
        <Cell span={2}>
          <img src="/logos/utech_logo.png" className={css({ marginRight: 0, marginBottom: 0 })} />
        </Cell>
        <Cell span={2}>
          <img
            src="/logos/actu_logo.png"
            className={css({ marginRight: 0, marginBottom: '34px' })}
          />
        </Cell>
        <Cell span={2}>
          <img
            src="/logos/ausunions_logo.png"
            className={css({ marginRight: 0, marginBottom: 0 })}
          />
        </Cell>
        <Cell span={2}>
          <img
            src="/logos/trades_hall_logo.png"
            className={css({ marginRight: 0, marginBottom: '40px' })}
          />
        </Cell>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <ParagraphLarge color={ourColors.white}>
            If you are interested in or have further questions about OHShelp, feel free to{' '}
            {contactLink}
          </ParagraphLarge>
          <ParagraphMedium color={ourColors.white} marginBottom="48px">
            All content © Australian Council of Trade Unions 2020
          </ParagraphMedium>
        </Cell>
      </Grid>
    </div>
  )
}
