import { Select } from './Select'
import React from 'react'
import { FormControl } from './FormControl'
import { getUnions } from '../api/cms'
import { Value } from 'baseui/select'

interface UnionSelectProps {
  value?: Value
  onChange: (option: Value | undefined) => void
  caption?: string
}

export const UnionSelect = (props: UnionSelectProps) => {
  const [unions, setUnions] = React.useState<Value[] | []>([])

  React.useEffect(() => {
    getUnions().then((value: Value[]) => {
      setUnions(value)
    })
  }, [])

  return (
    <FormControl label={'Union Name'} caption={props.caption}>
      <Select
        options={unions}
        required={true}
        onChange={value => props.onChange(value.value)}
        value={props.value}
        backspaceRemoves={false}
        clearable={false}
        id="union_name"
      />
    </FormControl>
  )
}
