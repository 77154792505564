import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import React from 'react'
import { styled } from 'baseui'

import { Theme } from 'baseui/theme'

export const Link = styled<{}, React.ComponentClass<GatsbyLinkProps<{}>, {}>, Theme>(
  GatsbyLink,
  ({ $theme }) => {
    const { colors, typography, animation } = $theme
    return {
      color: colors.linkText,
      ...typography.font350,
      fontSize: 'inherit',
      lineHeight: 'inherit',
      transitionProperty: 'color',
      transitionDuration: animation.timing100,
      transitionTimingFunction: animation.easeOutCurve,
      ':visited': {
        color: colors.linkVisited
      },
      ':hover': {
        color: colors.linkHover
      },
      ':active': {
        color: colors.linkActive
      }
    }
  }
)
