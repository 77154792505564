import { Value } from 'baseui/select'
import { Select } from './Select'
import { Role } from '../api/user'
import React from 'react'
import { FormControl } from './FormControl'

export interface RoleOption {
  label: 'Health and Safety Representative' | 'Organiser'
  id: Role
}

export const roleOptions: RoleOption[] = [
  { label: 'Health and Safety Representative', id: 'HSR' },
  { label: 'Organiser', id: 'Organiser' }
]

interface RoleSelectProps {
  value?: Value
  onChange: (option: Value | undefined) => void
}

export const RoleSelect = (props: RoleSelectProps) => {
  return (
    <FormControl label={'Which are you?'}>
      <Select
        options={roleOptions}
        required={true}
        onChange={value => props.onChange(value.value)}
        value={props.value}
        backspaceRemoves={false}
        clearable={false}
      />
    </FormControl>
  )
}
