import React from 'react'
import { FormControl } from '../components/FormControl'
import { Input } from '../components/Input'
import { useForm, Controller } from 'react-hook-form'
import { Checkbox } from '../components/Checkbox'
import { LABEL_PLACEMENT } from 'baseui/checkbox'
import { Button } from '../components/Button'
import { RoleSelect, roleOptions, RoleOption } from '../components/RoleSelect'
import { UnionSelect } from '../components/UnionSelect'
import { Value } from 'baseui/select'
import { createUser } from '../api/user'
import { Link } from '../components/Link'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Notification } from '../components/Notification'
import { ParagraphLarge } from 'baseui/typography'

interface FormValues {
  name: string
  preferred_name?: string
  phone_number?: string
  email: string
  union_name: Value
  union_membership_number: string
  role: RoleOption[]
  agree: boolean
}

interface RegistrationFormErrorResponse {
  response: {
    data: {
      [key: string]: string[] | undefined
      name?: string[]
      preferred_name?: string[]
      phone_number?: string[]
      email?: string[]
      union_name?: string[]
      union_membership_number?: string[]
      role?: string[]
    }
  }
}

interface RegistrationFormProps {
  maxColumns: number
  maxWidth: number
}

export const RegistrationForm = (props: RegistrationFormProps) => {
  const { handleSubmit, register, errors, setError, control } = useForm<FormValues>({
    defaultValues: {
      role: [roleOptions[0]]
    }
  })
  const [agree, setAgree] = React.useState(true)
  const [signupSuccessful, setSignupSuccessful] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const errorMessageCopy = 'Sorry, there was an error while signing up.'
  const success = () => {
    setIsLoading(false)
    setErrorMessage(false)
    setSignupSuccessful(true)
  }
  const failure = (error: RegistrationFormErrorResponse) => {
    Object.keys(error.response.data).map(
      key => setError(key, 'response', error.response.data[key]!.join(' ')) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    )
    setIsLoading(false)
    setErrorMessage(true)
  }

  const onSubmit = (values: FormValues) => {
    if (agree) {
      setIsLoading(true)
      const data = {
        email: values.email,
        name: values.name,
        preferred_name: values.preferred_name,
        phone_number: values.phone_number,
        union_membership_number: values.union_membership_number,
        union: values.union_name[0].id,
        role: values.role[0].id
      }
      createUser(data)
        .then(success)
        .catch(failure)
    } else {
      setError('agree', 'required', 'Agreement is required')
    }
  }

  const privacyPolicyLink = (
    <Link to="/conditions?title=ACTU%20UTECH%20Apps%20Privacy%20Policy">privacy policy</Link>
  )
  const dataCollectionPolicyLink = (
    <Link to="/conditions?title=ACTU%20Org%20Centre%20Information%20Collection%20Notice%20and%20Consent%E2%80%93%20UTECH%20Apps">
      data collection policy
    </Link>
  )

  const successContent = (
    <ParagraphLarge>
      Thanks for signing up to OHShelp, we will send a link for setting your password to the email
      you have provided.
    </ParagraphLarge>
  )

  const formContent = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid gridColumns={[4, props.maxColumns, props.maxColumns]} gridMaxWidth={props.maxWidth}>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl label="Full name" error={errors.name?.message}>
            <Input
              id="name"
              name="name"
              type="text"
              inputRef={register({ required: 'Full name is required' })}
            />
          </FormControl>
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl label="Preferred name" error={errors.preferred_name?.message}>
            <Input
              id="preferred_name"
              name="preferred_name"
              type="text"
              inputRef={register({ required: 'Preferred name is required' })}
            />
          </FormControl>
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl label="Phone number" error={errors.phone_number?.message}>
            <Input
              id="phone_number"
              name="phone_number"
              type="text"
              inputRef={register({ required: false })}
            />
          </FormControl>
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl label="Email" error={errors.email?.message}>
            <Input
              id="email"
              name="email"
              type="text"
              inputRef={register({
                required: 'Email is required'
              })}
            />
          </FormControl>
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <Controller
            rules={{ required: true }}
            as={UnionSelect}
            name="union_name"
            control={control}
            caption={'Please note that your union will be notified'}
          />
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl
            label="Union membership number"
            error={errors.union_membership_number?.message}
          >
            <Input
              id="union_membership_number"
              name="union_membership_number"
              type="text"
              inputRef={register({ required: 'Union membership number is required' })}
            />
          </FormControl>
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <Controller
            rules={{ required: true }}
            as={RoleSelect}
            name="role"
            control={control}
            defaultValue={[roleOptions[0]]}
          />
        </Cell>
        <Cell span={[4, props.maxColumns, props.maxColumns]} align={ALIGNMENT.center}>
          <FormControl error={errors.agree?.message}>
            <Checkbox
              checked={agree}
              onChange={e => setAgree(e.currentTarget.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              I agree to the {privacyPolicyLink} and {dataCollectionPolicyLink}.
            </Checkbox>
          </FormControl>
        </Cell>
        <Cell span={[4, 6]}>
          {errorMessage && <Notification kind={'negative'}>{errorMessageCopy}</Notification>}
        </Cell>
        <Cell span={2.25}>
          <Button
            kind="secondary"
            isLoading={isLoading}
            type="submit"
            overrides={{
              BaseButton: {
                style: {
                  marginTop: '12px',
                  width: '75%'
                },
                props: {
                  id: 'register-button'
                }
              }
            }}
          >
            Sign up
          </Button>
        </Cell>
      </Grid>
    </form>
  )

  return signupSuccessful ? successContent : formContent
}
