import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import { Button } from '../components/Button'
import { Input } from '../components/Input'
import { Checkbox } from '../components/Checkbox'
import { FormControl } from '../components/FormControl'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Tabs, Tab } from '../components/Tabs'
import { LABEL_PLACEMENT } from 'baseui/checkbox'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { ParagraphMedium } from 'baseui/typography'
import { WindowLocation } from '@reach/router'
import { Link } from '../components/Link'
import { ErrorNotification } from '../components/Notification'
import { login, LoginResponse, LoginErrors } from '../api/user'
import { useStyletron } from 'baseui'
import { getQueryVariable } from '../utils'
import { LogoFooter } from '../components/LogoFooter'
import { Value } from 'baseui/select'
import { RoleOption } from '../components/RoleSelect'
import { RegistrationForm } from '../components/RegistrationForm'
import { navigate } from 'gatsby'

export interface RegisterFormValues {
  fullName: string
  preferredName: string
  email: string
  unionName: Value
  role: RoleOption[]
  agree: boolean
}

export interface RegisterFormErrorResponse {
  response: {
    data: {
      [key: string]: string[] | undefined
      full_name?: string[]
      preferred_name?: string[]
      email?: string[]
      union_name?: string[]
      role?: string[]
    }
  }
}

const SignupTabContent = () => {
  return <RegistrationForm maxColumns={6} maxWidth={1000} />
}

const LoginTabContent = () => {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorReasons, setErrorReasons] = React.useState<LoginErrors | null>(null)
  const [remember, setRemember] = React.useState(true)
  const [loggingIn, setLoggingIn] = React.useState(false)
  const [loginError, setLoginError] = React.useState(false)
  const { setUserContext } = React.useContext(UserContext)
  const [css] = useStyletron()

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()
    if (setUserContext) {
      const successFunction = (loginResponse: LoginResponse) => {
        setUserContext(loginResponse, remember)
        setLoggingIn(false)
      }
      const failedFunction = (reasons: LoginErrors) => {
        setLoggingIn(false)
        setLoginError(true)
        setErrorReasons(reasons)
      }
      setLoggingIn(true)
      login(username, password, successFunction, failedFunction)
    }
    return false
  }

  let emailError = null
  let passwordError = null
  let nonFieldError = null
  if (errorReasons) {
    emailError = errorReasons.email ? errorReasons.email.join(' ') : null
    passwordError = errorReasons.password ? errorReasons.password.join(' ') : null
    nonFieldError = errorReasons.non_field_errors ? errorReasons.non_field_errors.join(' ') : null
  }
  const passwordLabel = (
    <>
      Password
      <div className={css({ display: 'flex', float: 'right' })}>
        <Link to="/reset-password"> Forgot your password? </Link>
      </div>
    </>
  )

  return (
    <form onSubmit={submitForm}>
      <ErrorNotification
        hasError={loginError}
        errorMessage={nonFieldError || 'There was an error while logging in.'}
      />
      <FormControl label="Email" caption={() => ''} error={emailError}>
        <Input id="email" value={username} onChange={e => setUsername(e.currentTarget.value)} />
      </FormControl>
      <FormControl label={passwordLabel} caption={() => ''} error={passwordError}>
        <Input
          id="password"
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
          type="password"
          clearable={true}
        />
      </FormControl>
      <FormControl>
        <Checkbox
          checked={remember}
          onChange={e => setRemember(e.currentTarget.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          Remember me
        </Checkbox>
      </FormControl>
      <Button
        kind="secondary"
        isLoading={loggingIn}
        onClick={submitForm}
        data-testid={!loggingIn ? 'login-button' : 'login-button-loading'}
        overrides={{
          BaseButton: {
            style: {
              marginTop: '12px'
            }
          }
        }}
      >
        Login
      </Button>
    </form>
  )
}

const ContentTabs = () => {
  const [activeKey, setActiveKey] = React.useState('0')

  return (
    <Grid>
      <Cell span={[4, 6]} align={ALIGNMENT.start}>
        <Tabs
          onChange={({ activeKey }) => {
            setActiveKey(activeKey.toString())
          }}
          activeKey={activeKey}
        >
          <Tab title="Log in">
            <LoginTabContent />
          </Tab>
          <Tab title="Sign up">
            <SignupTabContent />
          </Tab>
        </Tabs>
      </Cell>
    </Grid>
  )
}

const LoginOnlyContent = () => {
  return (
    <>
      <Grid>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <LoginTabContent />
          <ParagraphMedium overrides={{ Block: { style: { fontSize: '15px' } } }}>
            Please note that OHS Help is currently in early release testing. By logging in you agree
            to our{' '}
            <Link to="/conditions?title=ACTU%20UTECH%20Apps%20Privacy%20Policy">
              privacy policy
            </Link>{' '}
            and{' '}
            <Link to="/conditions?title=ACTU%20Org%20Centre%20Information%20Collection%20Notice%20and%20Consent%E2%80%93%20UTECH%20Apps">
              data collection policy
            </Link>
            .
          </ParagraphMedium>
        </Cell>
      </Grid>
    </>
  )
}

interface LoginPageProps {
  query: string
}
export const LoginPage = (props: LoginPageProps) => {
  const { token } = React.useContext(UserContext)
  const redirect = getQueryVariable('redirect', props.query)

  const signupEnabled = process.env.GATSBY_SIGNUP_ENABLED

  React.useEffect(() => {
    if (token) {
      if (redirect) {
        navigate(redirect.startsWith('/') ? redirect : '/' + redirect)
      } else {
        navigate('/')
      }
    }
  }, [token, redirect])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <HeadingLevel>
            <Heading>Log in or sign up</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      {signupEnabled ? <ContentTabs /> : <LoginOnlyContent />}
      <LogoFooter marginTop="52px" />
    </>
  )
}

interface LayoutLoginPage {
  location: WindowLocation
}
const LayoutLoginPage = (props: LayoutLoginPage) => {
  return (
    <Layout showNav={false} searchBarAvailable={false} backButton={false}>
      <LoginPage query={props.location.search} />
    </Layout>
  )
}

export default LayoutLoginPage
